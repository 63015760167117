import React from 'react';
import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';

export const Input = ({
  name,
  required,
  error,
  ref,
  onChange,
  onBlur,
  ...props
}) => {
  return (
    <div className='w-full flex flex-col'>
      <label
        className={`text-base text-[#081F3A] capitalize ${
          error && 'text-red-500'
        }`}
        htmlFor={name}
      >
        {required && <span className='requiredArt'>*</span>}
        {name}
      </label>
      <input
        {...props}
        id={name}
        onChange={onChange} // assign onChange event
        onBlur={onBlur} // assign onBlur event
        name={name} // assign name prop
        ref={ref}
        className={`editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
          error && 'border-red-700'
        }`}
      />
    </div>
  );
};

// input with pre added text
export const InputPreText = ({
  name,
  required,
  error,
  pretext,
  ref,
  onChange,
  onBlur,
  ...props
}) => {
  return (
    <div className='w-full flex flex-col'>
      <label
        className={`text-base text-[#081F3A] capitalize ${
          error && 'text-red-700'
        }`}
        htmlFor={name}
      >
        {required && <span className='requiredArt'>*</span>}
        {name}
      </label>
      <div
        className={` flex items-center editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 group-focus:outline-none  rounded  ${
          error && 'border-red-700'
        }`}
      >
        <p className=' '>{pretext}</p>
        <input
          {...props}
          id={name}
          onChange={onChange} // assign onChange event
          onBlur={onBlur} // assign onBlur event
          name={name} // assign name prop
          ref={ref}
          className={` focus:outline-none border-none group px-2 w-full`}
        />
      </div>
    </div>
  );
};

export const InputPhone = ({ name, required, error, pretext, ...props }) => {
  return (
    <div className='w-full flex flex-col'>
      <label
        className={`text-base text-[#081F3A] capitalize ${
          error && 'text-red-700'
        }`}
        htmlFor={name}
      >
        {required && <span className='requiredArt'>*</span>}
        {name}
      </label>
      <div
        className={`overflow-none  flex items-center editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] px-2 py-1 group-focus:outline-none  rounded  ${
          error && 'border-red-700'
        }`}
      >
        <PhoneInput
          country={'us'}
          inputStyle={{ border: 'none', width: '100%' }}
          buttonStyle={{ border: 'none', background: 'transparent' }}
          {...props}
        />
      </div>
    </div>
  );
};

export const TextArea = ({ name, required, error, ref, ...props }) => {
  return (
    <div className='w-full flex flex-col'>
      <label
        className={`text-base text-[#081F3A] capitalize ${
          error && 'text-red-700'
        }`}
        htmlFor={name}
      >
        {required && <span className='requiredArt'>*</span>}
        {name}
      </label>
      <textarea
        {...props}
        id={name}
        rows='3'
        ref={ref}
        className={`!resize-none editInput placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
          error && 'border-red-700'
        }`}
      />
    </div>
  );
};
