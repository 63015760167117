import axios from 'axios';
import slugify from 'slugify';
import { nanoid } from 'nanoid';

export const handleFileUpload = (event, setImage) => {
  let reader = new FileReader();
  let file = event.target.files[0];
  reader.onloadend = () => {
    setImage({
      file: reader.result,
      nft: file,
    });
  };
  reader.readAsDataURL(file);
};
export const handleFileCoverUpload = (event, setCover) => {
  let reader = new FileReader();
  let file = event.target.files[0];
  reader.onloadend = () => {
    setCover({
      file: reader.result,
      nft: file,
    });
  };
  reader.readAsDataURL(file);
};

//prepare ipfs structure
export const ipfsFilePrepare = ({ name, desc }, data) => {
  
  const imgPayload = {
    name: slugify(`${name} ${nanoid()}_image`, '_'),
    keyvalues: {
      description: desc,
    },
  };

  const metadata = JSON.stringify(imgPayload);

  data.append('pinataMetadata', metadata);

  //pinataOptions are optional
  const pinataOptions = JSON.stringify({
    cidVersion: 0,
    customPinPolicy: {
      regions: [
        {
          id: 'FRA1',
          desiredReplicationCount: 1,
        },
        {
          id: 'NYC1',
          desiredReplicationCount: 2,
        },
      ],
    },
  });

  data.append('pinataOptions', pinataOptions);

  return { pindata: data, imgPayload };
};

export const ipfsJsonPrepare = ({ name, desc }) => {
  let metaFile = {
    name: name,
    description: desc,
    image: '',
    edition: 1,
    attributes: [
      [
        {
          trait_type: 'date',
          value: Date.now(),
        },
      ],
    ],
    compiler: 'Mola Digital',
  };

  return metaFile;
};

export const nftUpload = async (url, data, status, imageHash) => {
  if (!status) {
    return new Promise((resolve, reject) => {
      resolve({
        data: {
          IpfsHash: imageHash,
        },
      });
    });
  }

  const document = await axios.post(url, data, {
    maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      pinata_api_key: "1af56d6c9fb885ef64b4",
      pinata_secret_api_key: "482473b1b50c0127c68bc0e085d1b84b786cdd1ef9c60e644bcef34e5274e286",
    },
  });

  return document;
};

export const nftUploadForCreate = async (url, data) => {
  const document = await axios.post(url, data, {
    maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      pinata_api_key: "1af56d6c9fb885ef64b4",
      pinata_secret_api_key: "482473b1b50c0127c68bc0e085d1b84b786cdd1ef9c60e644bcef34e5274e286",
    },
  });

  return document;
};

export const prepareIpfsMetadata = async (
  url,
  response,
  metaFile,
  imgPayload
) => {
  //handle response here

  metaFile.image = `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}/`;

  const metaPayload = {
    name: slugify(`${imgPayload.name}_meta`, '_'),
    keyvalues: {
      description: imgPayload.keyvalues.description,
    },
  };

  const pinPayload = {
    pinataMetadata: {
      name: `${metaPayload.name}_meta`,
      ...metaPayload,
    },
    pinataContent: {
      ...metaFile,
    },
  };

  return await axios.post(url, pinPayload, {
    headers: {
      pinata_api_key: "1af56d6c9fb885ef64b4",
      pinata_secret_api_key: "482473b1b50c0127c68bc0e085d1b84b786cdd1ef9c60e644bcef34e5274e286",
    },
  });
};
