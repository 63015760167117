import Web3 from "web3";
import PROVIDERS from "./config";
import ERC721ABI from "./ERC721ABI";
import Notiflix from "notiflix";


export const getWeb3Connection = (network) => {
  const selectProvider = PROVIDERS.getProvider(network);

  const connection = new Web3(selectProvider.jsonRpc);

  return connection;
};

// buffer gas limit
export const getBufferedGasLimit = (
  gasLimit,
  gasPriority
) => {
  return Math.round(Number(gasLimit) + Number(gasLimit) * Number(gasPriority));
};

// check wallet has sufficient balance for transaction
export const walletTransactionBalanceValidate = async (
  provider,
  bufferedGasLimit,
  senderWalletAddress,
  isNative,
  amountInWei
) => {
  const gasPrice = await provider.eth.getGasPrice();

  let transactionFee =
    parseFloat(gasPrice) * parseFloat(bufferedGasLimit.toString());
  if (isNative) {
    transactionFee = transactionFee + parseFloat(amountInWei.toString());
  }
  const balanceInWei = await provider.eth.getBalance(senderWalletAddress);

  if (transactionFee <= parseFloat(balanceInWei)) {
    return balanceInWei;
  }

  throw new Error(balanceInWei);
};

/*
    @info This method is used to send ERC20 (DAI,SAND,WBTC,AAVE) token to someone
    @param amountToSend -> This is the amount entered by the user in frontend , its in string format
    @param decimals -> its the decimals of the token selected by the user to send to someone
    @param contractInstance -> erc20 token contract instance
    @param tokenContractAddress -> erc20 token contract address
    @param recipientAddress -> recipient wallet address
    @params enderWalletAddress -> sender's wallet address
    @param sendersPrivateKey -> private key of sender
*/
export const sendERC721Token = async (
  network,
  tokenId,
  recipientAddress,
  senderWalletAddress,
  sendersPrivateKey,
  gasPriority,
  gasLimitCustom,
  tokenContractAddress,
  nonceCount
) => {
  try {
    const web3 = getWeb3Connection(network);

    // convert amount to wei

    const tokenInsatance = new web3.eth.Contract(
      ERC721ABI,
      tokenContractAddress
    );

    //get tx nonce
    let nonce = await web3.eth.getTransactionCount(senderWalletAddress)


    // calculate gas limit
    const gasLimit = await tokenInsatance.methods
      .safeTransferFrom(senderWalletAddress, recipientAddress, tokenId)
      .estimateGas({ from: senderWalletAddress });

    //bufferGasLimit for transaction time
    const gasPriorityBuffer = getBufferedGasLimit(gasLimit, gasPriority);

    // encode data of transaction
    const encodedData = tokenInsatance.methods
      .safeTransferFrom(senderWalletAddress, recipientAddress, tokenId)
      .encodeABI();

    // validate if user has sufficient balance for the transaction

    try {
        await walletTransactionBalanceValidate(
            web3,
            gasLimitCustom.toString() || gasPriorityBuffer.toString(),
            senderWalletAddress,
            false,
            "0"
          ); 

          const tx = {
            gas: web3.utils.toHex(gasLimit.toString()),
            to: tokenContractAddress,
            value: "0x00",
            data: encodedData,
            from: senderWalletAddress,
            nonce: web3.utils.toHex(nonce + nonceCount),
          };
      
          // signing transaction
          const signedTx = await web3.eth.accounts.signTransaction(
            tx,
            sendersPrivateKey
          );
      
          // return signed transaction
          const txReceipt = await web3.eth.sendSignedTransaction(
      
            signedTx.rawTransaction
      
          )
          
          .on('receipt', (receipt) => {
      
            return receipt;
      
          })
      
          .on('error', (error) => {
            console.log(error)
          })
      
          return txReceipt
          
    } catch (error) {
        Notiflix.Loading.remove();

          Notiflix.Report.failure('Error while Verifying Wallet!', error.message);

          console.log('Error while Verifying Wallet!', {...error});
        return
    }
  

    
    
  } catch (error) {

    Notiflix.Loading.remove();

    Notiflix.Report.failure('Error while Dispatching Airdrop!', error.message);

    console.log('Error while Dispatching Airdrop!', error);

    return;
  }
};

export const airdropService = async (
  network = 'MATIC',
  wallets,
  contractAddress,
  nextTokenId,
  senderAddress,
  privateKey
) => {

  wallets.forEach(async (wallet, nonceCount) => {

    try {

      setTimeout(async () => {
        await sendERC721Token(
          network,
          nextTokenId + nonceCount,
          wallet,
          senderAddress,
          privateKey,
          21000,
          0.001,
          contractAddress,
          nonceCount
        );
      }, 2000)

    } catch (error) {
      Notiflix.Loading.remove();

      Notiflix.Report.failure('Error while Dispatching Airdrop!', error.message);

      console.log('error in creating airdrop', error);

      console.log({...error});

      return;
    } 

  })

  return;
};
