import React from 'react'
import { sendEmailVerificationCode, verifyEmailVerificationCode } from '../api/user'


type Props = {
  email: string
}

export default function VerifyEmailAddress({email}: Props) {
  const [token, setToken] = React.useState('')
  const [sendCode, setSendCode] = React.useState({error: true, message: ""})
  const [verifyOtp, setVerifyOtp] = React.useState({error: true, message: "", loading: false})
  const [otp, setOtp] = React.useState("")

  const [res, setRes] = React.useState("")

  React.useEffect(()=>{
    const element:  HTMLElement | null  = document.querySelector('[name=csrf-token]')
    if (element instanceof HTMLMetaElement) {
      setToken(element.content)
    }
  })
  const sendVerificationCode = (e: Event) => {
    e.preventDefault();
    const data = {email}
   
    sendEmailVerificationCode(data, token).then((res) => {
      const data = {error: false, message: res.message}
      setSendCode(state => ({...state, ...data}))
      toastr.success(`Verification has be sent to ${email}`)
     
    }, (err) => {
      const error = {error: true, message: "Please input a valid email", status: ""}
      toastr.error(error.message)
      setSendCode(state => ({...state, ...error}))
     
    })
  }
  const verifyCode = (e: Event) => {
    e.preventDefault();
    setVerifyOtp(state => ({...state, loading: true}))
    const data = { email }
    verifyEmailVerificationCode(data, otp, token).then((res) => {
      const data = {error: false, message: res.message, loading: false}
      setVerifyOtp(state => ({...state, ...data}))
     
      toastr.success(res.message);
      location.reload();
    }, (err) => {
      const error = {error: true, message: "Please input a valid verification code", status: ""}
      toastr.error(error.message)
      setVerifyOtp(state => ({...state, ...error, loading: false}))
      console.log(err)
    })
  }

  return (
    <div>
      <h3 className='text-center mb-3'>Verify your Email Address</h3>
      {sendCode.error && (
        <div className="d-flex justify-content-center w-100percent">
          <button type="button" className="w-50percent sign__btn" onClick={sendVerificationCode}>Verify</button>
        </div>
      )}
      {!sendCode.error && (
        <form onSubmit={verifyCode} className="text-center ">
          <h5 className='w-50percent mx-auto mb-3'>An email has been sent to {email}</h5>
          <p className='mb-1 sign__input-modal-paragraph'>Please provide the verification code below</p>
          <div className="sign_input-container mb-3">
            <input type="text" name="otp-code" onChange={(e) => setOtp(e.target.value)} className="sign__input" placeholder='Verification code' />
          </div>
          <div className="d-flex justify-content-center w-100percent">
            <button type="submit" className="w-50percent sign__btn" disabled={ verifyOtp.loading}>Continue</button>
          </div>
        </form>
      )}
      <div style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
        <a href='#' style={{textDecoration: 'none', fontSize: 14}}>Terms of service</a>
        <a href='#' style={{textDecoration: 'none', fontSize: 14, marginLeft: 10}}>User agreement</a>
      </div>

    </div>
  )
}
