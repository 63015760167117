import { Controller } from "stimulus";
import Notiflix from "notiflix";
import axios from 'axios';


const CustomApi = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});
export default class extends Controller {

  async generateOfferWhitelist() {
    const address = document.querySelector('[name="offer_whitelist[address]"]');
    const id = document.getElementById('offer-id');
    let placeholder = ''
    const data = await CustomApi.get(`admin/manage_whitelist/${id.value}`)
    const array = await data
    if (!array.data.success) {
      Notiflix.Loading.arrows('Generating Whitelist')
    } else {
      Notiflix.Loading.remove()
      array.data.nft.forEach(element => {
        placeholder = `${placeholder}\n` + element;
      });
      address.disabled = true
      address.value = placeholder.trim();
    }
  }
  manualOfferWhitelist() {
    const address = document.querySelector('[name="offer_whitelist[address]"]');
    address.disabled = false;
    address.value = "";
  }

  async manageOffer(e) {
    // e.preventDefault();
    const generate = document.querySelector('[name="offer_whitelist[address]"]');
    generate.disabled = false
    const token = document.querySelector('[name=csrf-token]')
    const id = document.getElementById('offer-id');
    let address;
    if (generate.value.trim().length > 0) {
      address = generate.value.trim().split('\n')
    } 
    const data = await CustomApi.post('admin/offer_whitelists', {
      authenticity_token: token.content,
      offer_id: id.value,
      address: address.join(',').replace(/\s/g, "")
    })
    console.log(address)
    console.log(token.content);
    console.log(id.value);
  }
}