import React from 'react';
import CoverProfile from './CoverProfile';
import UserForm from './Form';

const UserDetailsConatiner = ({ data }) => {
  return (
    <div className='formBg grid grid-cols-1 gap-40'>
  
      <UserForm data={data} />
    </div>
  );
};

export default UserDetailsConatiner;
