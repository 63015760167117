require('dotenv').config();

const axios = require('axios');

const moralisBase = 'https://deep-index.moralis.io/api/v2';

const METHODS = {
    GET: 'GET',
}

let hasTokens = false;

const verifyWallet = async (wallet, assets, network) => {

    const options = {

        method: METHODS.GET,

        url: `${moralisBase}/${wallet}/nft`,

        params: {chain: network, format: 'decimal'},

        headers: {
          accept: 'application/json',
          'X-API-Key': process.env.MORALIS_KEY || 'HQ45rH10GU8B65Y3f1HYhg1iiQgbFoB2IBJjZD9sgyyElCaOFLdxxCCzALW3g1WB'
        }

      };

      try {

        const result = await axios.request(options);

        for (let token of result.data.result) {
          if(assets.includes(token.token_id)){

            hasTokens = true;
            
            break;

          }
        }
      } catch (error) {

        console.log({...error}, 'error');

      }

      return hasTokens;
}

export default verifyWallet;


/* (async () => {
  await verifyWallet('0x4c200851C5BeD4456175f3183fF76a1bCc73f48C', [
    '56930163533861193935171699756209854822368293045531932816878204024345465978980',
    '56930163533861193935171699756209854822368293045531932816878204017748396212324',
    '56930163533861193935171699756209854822368293045531932816878204018847907840100',
    '56930163533861193935171699756209854822368293045531932816878204025444977606706',
    '56930163533861193935171699756209854822368293045531932816878204026544489234482',
    '56930163533861193935171699756209854822368293045531932816878204027644000862258',
  ],'eth')
})() */
