export default {
    "ETHEREUM": {
        jsonRpc: 'https://powerful-restless-county.quiknode.pro/f19af7469a043be82fb2b88d6b5867839ca62d6d/',
        "chainId": 1,
        "chainName": "ETHEREUM",
        "nativeCurrency": {
            "name": "ETHEREUM",
            "symbol": "ETH",
            "decimals": 18
        },
        "blockExplorer": "https://etherscan.io",
        "test": false
    },
    "BINANCE": {
        jsonRpc: 'https://compatible-blue-shadow.bsc.quiknode.pro/7c3d58c75a2f2fdfc717d3913fd9c784f01f5b5f/',
        "chainId": 56,
        "chainName": "BINANCE",
        "nativeCurrency": {
            "name": "BINANCE",
            "symbol": "BNB",
            "decimals": 18
        },
        "blockExplorer": "https://bscscan.com",
        "test": false
    },
    "POLYGON": {
        jsonRpc: 'https://radial-flashy-card.matic.quiknode.pro/4bb1a5dc089aaf672b58bd1605689cc2aba46cb1/',
        "chainId": 137,
        "chainName": "POLYGON",
        "nativeCurrency": {
            "name": "POLYGON",
            "symbol": "MATIC",
            "decimals": 18
        },
        "blockExplorer": "https://polygonscan.com",
        "test": false
    },
    "GOERLI": {
        jsonRpc: 'https://powerful-restless-county.quiknode.pro/f19af7469a043be82fb2b88d6b5867839ca62d6d/',
        "chainId": 5,
        "chainName": "GOERLI",
        "nativeCurrency": {
            "name": "GOERLI",
            "symbol": "ETH",
            "decimals": 18
        },
        "blockExplorer": "https://goerli.etherscan.io",
        "test": true
    },
    "T_BINANCE": {
        jsonRpc: 'https://powerful-restless-county.quiknode.pro/f19af7469a043be82fb2b88d6b5867839ca62d6d/',
        "chainId": 97,
        "chainName": "T_BINANCE",
        "nativeCurrency": {
            "name": "T_BINANCE",
            "symbol": "BNB",
            "decimals": 18
        },
        "blockExplorer": "https://testnet.bscscan.com",
        "test": true
    },
    "MUMBAI": {
        jsonRpc: 'https://fabled-methodical-ensemble.matic-testnet.quiknode.pro/516cdd4937ecc88c36a23b5fd85cb51676ee22bc/',
        "chainId": 80001,
        "chainName": "MUMBAI",
        "nativeCurrency": {
            "name": "MUMBAI",
            "symbol": "MATIC",
            "decimals": 18
        },
        "blockExplorer": "https://mumbai.polygonscan.com",
        "test": true
    }
}