import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["info", 'infoBtn']
  initialize() {
    const userOs = window.navigator.userAgent.toLowerCase()
    if (this.hasInfoBtnTarget) {
      this.infoBtnTarget.addEventListener('click', () => {
        this.infoTarget.classList.add('mfp-hide');
      })
      if(userOs.indexOf('android') !== -1){
        this.infoTarget.classList.remove('mfp-hide');
      }
    }
  }
}
