// import * as React from 'react';
// import PhoneInput from 'react-phone-input-2'

// export interface IPhoneNumberInputProps {
//   phoneNumber: string;
// }

// export function PhoneNumberInput ({ phoneNumber}: IPhoneNumberInputProps) {
//   const [number, setNumber] = React.useState(phoneNumber);
//   return (
//     <div>
//       <PhoneInput
//         country={'us'}
//         value={phoneNumber}
//         onChange={phone => setNumber(phone)}
//       />
//       <input type="hidden" name="user[phone_number]" value={phoneNumber} />
//     </div>
//   );
// }

import React from 'react'
import PhoneInput from 'react-phone-input-2'

type Props = {
  phoneNumber: string | null
}

export default function PhoneNumberInput({ phoneNumber}: Props) {
  const [number, setNumber] = React.useState(phoneNumber);
    return (
    <div>
      <PhoneInput
        country={'us'}
        value={phoneNumber}
        onChange={phone => setNumber(phone)}
      />
      <input type="hidden" name="user[phone_number]" value={phoneNumber} />
    </div>
  );
}
