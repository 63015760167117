const axios = require('axios');
const moralisBase = 'https://deep-index.moralis.io/api/v2';
const METHODS = {
    GET: 'GET',
}

const metadataFetch = async (address, tokenIds, network) => {

    const queryBucket = [];

    const metadata = [];

    const queryOptions = (tokenId) => ({

        method: METHODS.GET,

        url: `${moralisBase}/nft/${address}/${tokenId}?chain=${network}&format=decimal`,

        headers: {
          accept: 'application/json',
          'X-API-Key': process.env.MORALIS_KEY || 'HQ45rH10GU8B65Y3f1HYhg1iiQgbFoB2IBJjZD9sgyyElCaOFLdxxCCzALW3g1WB'
        }

      });

      try {

        // console.log(result.data)

        for (let tokenId of tokenIds) {

          //prepare list of token ids to be queried

          const resultSauce = await axios.request(queryOptions(tokenId));

          queryBucket.push(resultSauce)
        }

        const metaPrepare = Promise.all(queryBucket);

        (await metaPrepare).forEach(prop => {

          if(prop.data)

            metadata.push(prop.data);

        });

      } catch (error) {

        console.log(error, 'error');

      }
      console.log(metadata);

      return metadata
  }

  export default metadataFetch;
