import React, { useState, useEffect } from 'react';
import ModalContainer from './ModalContainer';
import { UploadDocButton } from './UploadDocumentButton';
import { DocumentUploadContainer } from './ModalDesign/DocumentUploadDesign';
export const UploadDocument = ({ register, docImage, setValue,idImage }) => {
  const [imageDoc, setImageDoc] = useState();
  const [openModal, setOpenModal] = useState();
  // useEffect(() => {
  //   const selectedFile = document.getElementById('ktpUpload').files[0];

  //   let reader = new FileReader();
  //   reader.onloadend = () => {
  //     setImageDoc(reader.result)
  //   }
  // },[]);
  // console.log(imageDoc,':file');
  return (
    <div className='flex flex-col space-y-4 upload-doc'>
      <h5 className={`upload-doc-title`}>Upload Your Document</h5>
      <p className='upload-doc-text'>
        You need to upload your ID document, Passport, Kitas or Driver License
        to have possibility to buy a ticket.
      </p>
      <input
        name='ind_ktp_id_image'
        type='file'
        ref={register()}
      
        
        className='form-control-file hidden'
        id='ktpUpload'
      />
         <label
         htmlFor='ktpUpload'
        className='upload-doc-button'
      >
        Upload 
      </label>
      <div className='h-[120px] w-[300px] overflow-hidden '>
        <img
          src={idImage}
          className='h-[120px] w-[300px] rounded-full object-cover z-0'
          alt='profile'
        />
      </div>
      <button
        onClick={() => setOpenModal(!openModal)}
        className='upload-doc-button'
      >
        Upload Document
      </button>

      <ModalContainer
        closeModal={() => setOpenModal(!openModal)}
        openModal={openModal}
        overlay={'modal-overlay'}
        className={'modal-container'}
      >
        <DocumentUploadContainer
          setValue={setValue}
          closeModal={() => setOpenModal(!openModal)}
          register={register}
        />
      </ModalContainer>
    </div>
  );
};
