import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form/dist/index';

const FormInput = ({ register, errors, control }) => {
  const handlePhoneChange = (e) => {
    const phoneNumber = parsePhoneNumber(`+${e}`);
    setValidPhone(!!phoneNumber);
  };
  return (
    <div className=' flex flex-col gap-4'>
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-4'>
        <div className='w-full flex flex-col'>
          <label
            className={`text-base text-[#081F3A] capitalize ${
              false && 'text-red-500'
            }`}
            htmlFor={'fullname'}
          >
            {true && <span className='requiredArt'>*</span>}
            {'Username'}
          </label>
          <input
            type='text'
            id={'fullname'}
            ref={register({
              required: true,
            })}
            name={'fullname'}
            placeholder='Enter your user name'
            className={`editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
              errors && 'border-red-700'
            }`}
          />
          <ErrorMessage
            errors={errors}
            name='fullname'
            render={() => (
              <p className='form-error-text'>{'username is required!'}</p>
            )}
          />
        </div>
        <div className='w-full flex flex-col'>
          <label
            className={`text-base text-[#081F3A] capitalize ${
              false && 'text-red-500'
            }`}
            htmlFor={'userName'}
          >
            {true && <span className='requiredArt'>*</span>}
            {'Full name'}
          </label>
          <input
            type='text'
            id={'fullName'}
            ref={register({
              required: true,
            })}
            name={'fullName'}
            placeholder='Enter your user name'
            className={`editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
              false && 'border-red-700'
            }`}
          />
          <ErrorMessage
            errors={errors}
            name='fullName'
            render={() => (
              <p className='form-error-text'>{'full name is required!'}</p>
            )}
          />
        </div>
      </div>
      {/* email and phone */}
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-4'>
        <div className='w-full flex flex-col'>
          <label
            className={`text-base text-[#081F3A] capitalize ${
              false && 'text-red-500'
            }`}
            htmlFor={'email'}
          >
            {true && <span className='requiredArt'>*</span>}
            {'Email'}
          </label>
          <input
            type='email'
            id={'email'}
            ref={register({
              required: true,
            })}
            name={'email'}
            placeholder='Enter your Email'
            className={`editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
              false && 'border-red-700'
            }`}
          />
          <ErrorMessage
            errors={errors}
            name='email'
            render={() => (
              <p className='form-error-text'>{'email is required!'}</p>
            )}
          />
        </div>
        <div className='w-full flex flex-col'>
          <label
            className={`text-base text-[#081F3A] capitalize ${
              false && 'text-red-700'
            }`}
            htmlFor={'Phone number'}
          >
            {false && <span className='requiredArt'>*</span>}
            {'phone'}
          </label>
          <div
            className={`overflow-none  flex items-center editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] px-1 py-1 group-focus:outline-none  rounded  ${
              false && 'border-red-700'
            }`}
          >
            <Controller
              control={control}
              name='phone_number'
              // rules={{ required: true }}
              // defaultValue={user.phone_number}
              render={(prop) => (
                <>
                  <PhoneInput
                    {...prop}
                    country={'us'}
                    ref={register()}
                   
                    inputStyle={{ border: 'none', width: '100%' }}
                    buttonStyle={{ border: 'none', background: 'transparent' }}
                
                  />
                </>
              )}
            />
            {/* <PhoneInput
              country={'us'}
              name='phone_number'
              ref={register()}
              inputStyle={{ border: 'none', width: '100%' }}
              buttonStyle={{ border: 'none', background: 'transparent' }}
            /> */}
          </div>
        </div>
      </div>
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-4 items-center'>
        {/* <div className='w-full flex flex-col'>
          <label
            className={`text-base text-[#081F3A] capitalize ${
              false && 'text-red-700'
            }`}
            htmlFor={'twitter_link'}
          >
            {false && <span className='requiredArt'>*</span>}
            {'Twitter Username'}
          </label>
          <div
            className={` flex items-center editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8]  group-focus:outline-none  rounded  ${
              false && 'border-red-700'
            }`}
          >
            <p className='pl-2 '>@</p>
            <input
              id={'twitter_link'}
              name={'twitter_link'} // assign name prop
              ref={register()}
              type='text'
              className={` focus:outline-none border-none group px-2 w-full`}
            />
          </div>
        </div> */}
        {/* <div className='w-full flex flex-col'>
          <label
            className={`text-base text-[#081F3A] capitalize ${
              false && 'text-red-700'
            }`}
            htmlFor={'Porpersonal_urltfolio'}
          >
            {false && <span className='requiredArt'>*</span>}
            {'Portfolio'}
          </label>
          <div
            className={` flex items-center editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8]  group-focus:outline-none  rounded  ${
              false && 'border-red-700'
            }`}
          >
            <p className='pl-2'>https://</p>
            <input
              id={'personal_url'}
              name={'personal_url'} // assign name prop
              ref={register()}
              type='text'
              className={`active:bg-transparent focus:bg-transparent active:border-none focus:outline-none border-none group px-2 w-full`}
            />
          </div>
        </div> */}
      </div>
      {/* bio */}
     
    </div>
  );
};

export default FormInput;
