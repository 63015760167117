import ApplicationController from './application_controller'
export default class extends ApplicationController {
  toggle(event) {
    document.querySelectorAll('.banner-image').forEach((element) => {
      element.classList.remove('bg-view-banner');
    })
    const element = document.getElementById("page-image")
    element.classList.remove('img')
    if (!!event.target.parentElement.parentElement.classList.contains('banner-image')){
      event.target.parentElement.parentElement.classList.add('bg-view-banner')
    }else if (!!event.target.parentElement.classList.contains('banner-image')){
      event.target.parentElement.classList.add('bg-view-banner')
    }
    const image = event.currentTarget.dataset.banner
    document.querySelector('#page-image').innerHTML = image
  }
}
