import React, { useRef } from 'react';
import { FileDrop } from 'react-file-drop';
// import styles from 'styles/pages/user.module.css';
const regExpImage = /^(image\/.+?)$/;
export const UploadDocButton = ({ setValue,register, setImage, ...props }) => {
  const fileInputRef = useRef(null);
  const onFileInputChange = (event) => {
    const { files } = event.target;
    setValue('imagess', 'ppopo');
    let reader = new FileReader();
    let file = files[0];
    setValue('ind_ktp_id_image', files[0]);
    const isAll = regExpImage.test(file?.type);
    console.log('failed');
    if (isAll) {
      reader.onloadend = () => {
        setImage({
          view: reader.result,
          file: files,
        });
      };
      reader.readAsDataURL(file);

      props.imageType
        ? console.log('profile image update')
        : console.log('profile cover update');
    } else {
      console.log('failed');
    }
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className=' justify-self-end m-[40px]  '>
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type='file'
        className='hidden'
      />
      <FileDrop onTargetClick={onTargetClick} type="button">
        <div className='modal-button-light modals-btn w-full'>Choose file</div>
      </FileDrop>
    </div>
  );
};
