
import React,{ useRef } from 'react';
import { FileDrop } from 'react-file-drop';
// import styles from 'styles/pages/user.module.css';

export const UploadProfileImageButton = ({ fileFormat, ...props }) => {
  const fileInputRef = useRef(null);
  const onFileInputChange = (event) => {
    const { files } = event.target;
    let reader = new FileReader();
    let file = files[0];

    const isAll = fileFormat.test(file?.type);

    if (isAll) {
      reader.onloadend = () => {
        props.setImageFile({
          view: reader.result,
          file: files,
        });
      };
      reader.readAsDataURL(file);

      props.imageType
        ? console.log('profile image update')
        : console.log('profile cover update');
    } else {
      console.log('failed');
    }
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className='z-20  '>
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type='file'
        className='hidden'
      />
      <FileDrop onTargetClick={onTargetClick}>
        <div className='z-20 icon-camera  '>
          
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6  text-white'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
            />
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
            />
          </svg>
        </div>
      </FileDrop>
    </div>
  );
};
