import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form/dist/index';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
import { Input, InputPhone, InputPreText, TextArea } from './Common';
import FormInput from './FormInput';
import { UploadProfileImageButton } from './UploadComponent';
import { UploadCoverButton } from './UploadCover';
import { UploadDocument } from './UploadDcoument';
import ModalContainer from './ModalContainer';
import { ProfileModalContainer } from './ModalDesign/ProfileModal';
// const schema = yup
//   .object({
//     // firstName: yup.string().required(),
//     // age: yup.number().positive().integer().required(),
//   })
//   .required();

const UserForm = ({ data }) => {
  const { user, user_attachment, user_banner, ind_ktp_id_image } = data;

  const [imageFile, setImageFile] = useState(null);
  const [profileModal, setProfileModal] = useState(false);
  const [coverModal, setCoverModal] = useState(null);
  // const [profileImage, setProfileImage] = useState(null);
  const [imageCoverFile, setImageCoverFile] = useState(null);
  const [docImage, setDocImage] = useState();

  const regExpImage = /^(image\/.+?)$/;
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullname: user.fullname ?? '',
      name: user.name ?? '',
      email: user.email ?? '',
      personal_url: user.personal_url ?? '',
      twitter_link: user.bio ?? '',
      bio: user.bio ?? '',
      phone_number: user.phone_number ?? '',
    },
  });
  const onSubmit = (data) => console.log(data);
  const handleCha = () => {
    setValue('select', 'selectedValue');
    const form = getValues("ind_ktp_id_image");
    const all = getValues();
  
      const { files } = form;
      setValue('imagess', 'ppopo');
      let reader = new FileReader();
      let file = files;
      setValue('ind_ktp_id_imagee', files);
      // const isAll = regExpImage.test(file?.type);
      // console.log('failed');
      // if (isAll) {
        reader.onloadend = () => {
          setDocImage({
            view: reader.result,
            file: files,
          });
        };
        reader?.readAsDataURL(file);
  
        // props.imageType
        //   ? console.log('doc image update')
        //   : console.log('doc cover update');
      // } else {
      //   console.log('failed');
      // }
 
    console.log('clicked', form, all);
  };
  console.log('clicccccc', docImage);
  return (
    <>
      <div className='coverBg '>
        <div
          className='relative w-full min-h-[300px] grid items-start p-8 mx-0 profile-cover-style'
          style={{
            backgroundImage: `url(${imageCoverFile?.view})`,
            backgroundPosition: 'center center',
            backgroundSize: 'center',
          }}
        >
          <UploadCoverButton
            fileFormat={regExpImage}
            setImageFile={setImageCoverFile}
            imageType={true}
          />
          {/* <div className='w-full h-full overflow-hidden '>{(cover|| imageCoverFile?.view)&&
                <img src={cover ?? imageCoverFile?.view } className='w-full  h-[200px]' alt={`cover`}/>}
            </div> */}
          <div className='profileImage  h-[120px] w-[120px] rounded-full shadow-xl bg-white z-10'>
            <div className='h-[120px] w-[120px] rounded-full overflow-hidden '>
              <img
                src={
                  'https://res.cloudinary.com/dnvsfxlan/image/upload/v1660493043/cld-sample-5.jpg' ??
                  imageFile?.view ??
                  'https://res.cloudinary.com/dnvsfxlan/image/upload/v1666007448/bg_mpiv2d.png'
                }
                className='h-[120px] w-[120px] rounded-full object-cover z-0'
                alt='profile'
              />
            </div>
            <div>
              <button
                onClick={() => setProfileModal(!profileModal)}
                className='z-20 icon-camera  '
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-4 h-4  text-white'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
                  />
                </svg>
              </button>
              {/* <UploadProfileImageButton
              fileFormat={regExpImage}
              setImageFile={setImageFile}
              imageType={true}
            /> */}
            </div>
          </div>
        </div>
      </div>
      <main className='w-full !mb-12 '>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='edit-form px-4 mx-auto flex flex-col gap-4'
        >
          <h1 className='section__title !mb-0 !mt`-40'>Profile Details</h1>
          <FormInput
            register={register}
            errors={errors}
            control={control}
            getValues={getValues}
          />
          <UploadDocument
            register={register}
            getValues={getValues}
            setValue={setValue}
            docImage={docImage}
           idImage={ind_ktp_id_image}
          />
          <div className='w-full flex flex-col'>
            <label
              className={`text-base text-[#081F3A] capitalize ${
                false && 'text-red-700'
              }`}
              htmlFor={'user_bio'}
            >
              {false && <span className='requiredArt'>*</span>}
              {'Bio'}
            </label>
            <textarea
              name={'user_bio'}
              id='user_bio'
              ref={register()}
              placeholder='Tell me about yourself in few words (MAX 100 symbols)'
              className={`!resize-none editInput placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
                false && 'border-red-700'
              }`}
            />
          </div>

          {/* private account */}
          <div className='my-4   private-account'>
            <h5 className={`capitalize text-base text-[#081F3A]`}>
              Private account
            </h5>

            <p className=' text-[#A297A4] text-sm'>
              Your personal information, such as your email address and phone
              number, will be hidden from other users.
            </p>
          </div>
          <button type='button' onClick={handleCha}>
            op
          </button>
          {/* button  */}
          <button
            type='submit'
            className='self-start edit-submit capitalize text-base font-bold text-white py-3 md:px-20 px-8'
          >
            update profile
          </button>
        </form>
      </main>
      <ModalContainer
        closeModal={() => setProfileModal(false)}
        openModal={profileModal}
        overlay={'modal-overlay'}
        className={'modal-container'}
      >
        <ProfileModalContainer closeModal={() => setProfileModal(false)} />
      </ModalContainer>
    </>
  );
};

export default UserForm;
