
import React, { useRef } from 'react';
import { FileDrop } from 'react-file-drop';
// import styles from 'styles/pages/user.module.css';

export const UploadCoverButton = ({ fileFormat, ...props }) => {
  const fileInputRef = useRef(null);
  const onFileInputChange = (event) => {
    const { files } = event.target;
    let reader = new FileReader();
    let file = files[0];

    const isAll = fileFormat.test(file?.type);

    if (isAll) {
      reader.onloadend = () => {
        props.setImageFile({
          view: reader.result,
          file: files,
        });
      };
      reader.readAsDataURL(file);

      props.imageType
        ? console.log('profile image update')
        : console.log('profile cover update');
    } else {
      console.log('failed');
    }
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className=' justify-self-end m-[40px]  '>
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type='file'
        className='hidden'
      />
      <FileDrop onTargetClick={onTargetClick}>
      <button className='border border-white bg-white/20 py-2 px-6 justify-self-end text-[17px] text-white '>
                Upload Banner
            </button>
      </FileDrop>
    </div>
  );
};
