import GLOBAL_RPCS from "./rpc";

export const PROVIDERS = {
  getSupportedNetworks() {
    return [
      'POLYGON',
      'ETHEREUM',
      'BINANCE',
      'GOERLI',
      'T_BINANCE',
      'MUMBAI',
    ];
  },

  getProvider(network) {
    let networkDetails;

    switch (network) {
      // case 'ETH':
      //   networkDetails = GLOBAL_RPCS.ETHEREUM;
      //   break;
      // case 'MATIC':
      //   networkDetails = GLOBAL_RPCS.POLYGON;
      //   break;
      // case 'BSC':
      //   networkDetails = GLOBAL_RPCS.BINANCE;
      //   break;
      case 'ETH':
        networkDetails = GLOBAL_RPCS.GOERLI;
        break;
      case 'BSC':
        networkDetails = GLOBAL_RPCS.T_BINANCE;
        break;
      case 'MATIC':
        networkDetails = GLOBAL_RPCS.MUMBAI;
        break;
      default:
        networkDetails = GLOBAL_RPCS.ETHEREUM;
    }

    return networkDetails;
  },
};

export default PROVIDERS;
