import React from 'react'
import { verifyPhoneNumber, verifyPhoneNumberOTP } from '../api/user'


type Props = {
  phone_number: string,
}

export default function VerifyPhoneNumber({phone_number}: Props) {
  const [token, setToken] = React.useState('')
  const [fetchOtp, setFetchOtp] = React.useState({error: true, message: ""})
  const [verifyOtp, setVerifyOtp] = React.useState({error: true, message: "", loading: false})
  const [otp, setOtp] = React.useState("")

  React.useEffect(()=>{
    const element:  HTMLElement | null  = document.querySelector('[name=csrf-token]')
    if (element instanceof HTMLMetaElement) {
      setToken(element.content)
    }
  })
  const sendCode = (e: Event) => {
    e.preventDefault();
    const data = {phone_number: phone_number}
    verifyPhoneNumber(data, token).then((res) => {
      const data = {error: false, message: res.message}
      setFetchOtp(state => ({...state, ...data}))
      toastr.success(`OTP has be sent to ${phone_number}`)

    }, (err) => {
      const error = {error: true, message: "Please input a valid phone number", status: ""}
      toastr.error(error.message)
      setFetchOtp(state => ({...state, ...error}))
      console.log(err)
    })
  }
  const verifyCode = (e: Event) => {
    e.preventDefault();
    setVerifyOtp(state => ({...state, loading: true}))
    const data = { phone_number}
    verifyPhoneNumberOTP(data, token, otp).then((res) => {
      const data = {error: false, message: res.message, loading: false}
      setVerifyOtp(state => ({...state, ...data}))

      toastr.success(res.message);
      location.reload();
    }, (err) => {
      const error = {error: true, message: "Please input a valid OTP", status: ""}
      toastr.error(error.message)
      setVerifyOtp(state => ({...state, ...error, loading: false}))
      console.log(err)
    })
  }

  return (
    <div>
      <h3 className='text-center mb-3'>Verify your phone number</h3>
      {fetchOtp.error && (
        <div className="d-flex justify-content-center w-100percent">
          <button type="button" className="w-50percent sign__btn" onClick={sendCode}>Verify</button>
        </div>
      )}
      {!fetchOtp.error && (
        <form onSubmit={verifyCode} className="text-center ">
          <h5 className='w-50percent mx-auto mb-3'>An SMS has been sent to {phone_number}</h5>
          <p className='mb-1 sign__input-modal-paragraph'>Please provide the verification code below</p>
          <div className="sign_input-container mb-3">
            <input type="text" name="otp-code" onChange={(e) => setOtp(e.target.value)} className="sign__input" placeholder='Verification code' />
          </div>
          <div className="d-flex justify-content-center w-100percent">
            <button type="submit" className="w-50percent sign__btn" disabled={ verifyOtp.loading}>Continue</button>
          </div>
        </form>
      )}
    </div>
  )
}
