import React, { useState } from 'react';
import { UploadProfileImageButton } from './UploadComponent';
import { UploadCoverButton } from './UploadCover';

const CoverProfile = ({ cover, profileImage }) => {
  const [imageFile, setImageFile] = useState(null);
  // const [profileImage, setProfileImage] = useState(null);
  const [imageCoverFile, setImageCoverFile] = useState(null);

  const regExpImage = /^(image\/.+?)$/;

  return (
    <div className='coverBg '>
      <div
        className=' relative w-full min-h-[300px] grid items-start p-8'
        style={{ backgroundImage: `url(${ imageCoverFile?.view})`, backgroundPosition: 'center center', backgroundSize: 'center' }}
      >
        <UploadCoverButton
          fileFormat={regExpImage}
          setImageFile={setImageCoverFile}
          imageType={true}
        />
        {/* <div className='w-full h-full overflow-hidden '>{(cover|| imageCoverFile?.view)&&
                <img src={cover ?? imageCoverFile?.view } className='w-full  h-[200px]' alt={`cover`}/>}
            </div> */}
        <div className='profileImage  h-[120px] w-[120px] rounded-full shadow-xl bg-white z-10'>
          <div className='h-[120px] w-[120px] rounded-full overflow-hidden '>
            <img
              src={
                profileImage ??
                imageFile?.view ??
                'https://res.cloudinary.com/dnvsfxlan/image/upload/v1666007448/bg_mpiv2d.png'
              }
              className='h-[120px] w-[120px] rounded-full object-cover z-0'
              alt='profile'
            />
          </div>
          <div>
            <UploadProfileImageButton
              fileFormat={regExpImage}
              setImageFile={setImageFile}
              imageType={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverProfile;
