import { Controller } from "stimulus";
import axios from "axios";

const CustomApi = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});

export default class extends Controller {
  initialize() {
    this.seatingApplicable();
    this.venueSelected();
  }


  seatingApplicable(e) {
    const seatingMap = document.getElementById("seating-map");
    const seatingEnable = document.getElementById("seatingMapEnable");
    const venueId = document.getElementById("venue-id");
    const seatMap = document.getElementById("seat");
    if (seatingEnable.checked) {
      seatingMap.style.display = "block";
    } else {
      seatingMap.style.display = "none";
    }
  }

  async venueSelected(e) {
    const id = document.getElementById("venue-id");
    const token = document.querySelector("[name=csrf-token]");
    const select = document.getElementById("seat");
    if (select[1] !== undefined) {
      $("#seat")
        .empty()
        .append('<option value ="0">Select a Venue Map</option>');
    }
    if (id.value) {
      const data = await CustomApi.get(`admin/filter_venue/${id.value}`);
      const options = await data.data;
      for (var i = 0; i < options.length; i++) {
        var opt = document.createElement("option");
        opt.value = options[i].id;
        opt.innerHTML = options[i].name;
        select.appendChild(opt);
      }
    }
  }
}
