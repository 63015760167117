import React,{useEffect, useState} from 'react';
import { UploadProfileImageButton } from '../UploadComponent';
import { UploadDocButton } from '../UploadDocumentButton';

export const DocumentUploadContainer = ({
  fileFormat,
  setValue,
  imageFile,
  closeModal,
  register,
}) => {

  const [image, setImage] = useState();
  const [toggle, setToggle] = useState(false);
  
 

  console.log(image,":img",toggle)
  return (
    <div className='grid grid-cols-1 justify-items-center gap-4 relative modals-container'>
      <h4 className='modal-headers !text-[32px]'>Upload Document</h4>
      <button
        onClick={closeModal}
        className='absolute top-2 right-2 text-black'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M6 18L18 6M6 6l12 12'
          />
        </svg>
      </button>
      <img
          src={
             image?.view   }
          className='h-[212px] w-[386px] object-cover z-0'
          alt='profile'
        />
      {false ? (
        <img
          src={
            'https://res.cloudinary.com/dnvsfxlan/image/upload/v1660493043/cld-sample-5.jpg' ??
            image?.view ??
            'https://res.cloudinary.com/dnvsfxlan/image/upload/v1666007448/bg_mpiv2d.png'
          }
          className='h-[212px] w-[386px] object-cover z-0'
          alt='profile'
        />
      ) : (
        <div className='h-[212px] w-[386px] p-20'  onClick={() => setToogle(!toggle)}>
          <p>
            Upload your document photo in current format: PNG, WEBP, JPEG, HEIC.
            Max 10mb.
          </p>
          <UploadDocButton
          setImage={setImage} setValue={setValue} register={register}/>
        </div>
      )}

      <div className='w-full flex flex-col'>
        <label
          className={`text-base text-[#081F3A] capitalize ${
            false && 'text-red-500'
          }`}
          htmlFor={'ind_ktp_id'}
        >
          {true && <span className='requiredArt'>*</span>}
          {'Document Number'}
        </label>
        <input
          type='text'
          id={'ind_ktp_id'}
          ref={register({
            required: true,
          })}
          name={'ind_ktp_id'}
          placeholder='Enter your user name'
          className={`editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded `}
        />
        {/* <ErrorMessage
          errors={errors}
          name='document-number'
          render={() => (
            <p className='form-error-text'>{'username is required!'}</p>
          )}
        /> */}
      </div>
     
   
      <div className='mt-2 grid grid-cols-1 gap-2 w-full'>
      
        <button  type='button'    onClick={closeModal} className='modal-button-dark modals-btn w-full'>
          {' '}
          Look's great
        </button>
      </div>
    </div>
  );
};
