import React from 'react';

export const UploadBannerModalContainer = ({
  register,
  user_banner,
  closeModal,
  open,
  handleDeleteBanner
}) => {
  return (
    <div
      className='upload_modal place-content-center'
      style={{ display: open ? 'grid' : 'none' }}
    >
      <div className='grid grid-cols-1 justify-items-center gap-4 relative modals-container'>
        <h4 className='modal-headers'>Upload Banner Photo</h4>
        <button
          type='button'
          onClick={closeModal}
          className='absolute top-2 right-2 text-black'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </button>
        <div className='h-[120px] w-[230px] rounded relative'>
          <div
            id='bannerModalPreview'
            style={{
              backgroundImage: `url(${user_banner})`,
              backgroundPosition: 'center center',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat'
            }}
            className='h-[120px] w-[230px] rounded object-contain z-0'
          >
             <input
                type='file'
                name='banner'
                ref={register()}
                className='hidden'
                id='bannerUpload'
              />
          </div>

          <label
            htmlFor='bannerUpload'
            className='cursor-pointer z-20 icon-camera'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-4 h-4  text-white'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
              />
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
              />
            </svg>
          </label>
        </div>

        <div className='mt-2 grid grid-cols-1 !gap-1 w-full'>
          <button type="button" onClick={handleDeleteBanner} className='mb-3 modal-button-red  modals-btn w-full cursor-pointer mb-2 !z-50'>
            Delete banner
          </button>
          <button
            type='button'
            onClick={closeModal}
            className='modal-button-dark modals-btn w-full'
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};
