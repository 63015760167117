import React, { useState } from 'react';

import Modal from 'react-modal';

const ModalContainer = (props) => {
  const { children, closeModal, openModal, className, overlay } = props;

  const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex:"9999"
      },
  };
  
  return (
    <Modal
      isOpen={openModal}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className={className}
      overlayClassName={overlay}
      ariaHideApp={false}
      contentLabel='Modal'
    >
      {children}
    </Modal>
  );
};

export default ModalContainer;
